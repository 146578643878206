<template>
  <div class="pa-4">
    <div v-if="$store.state.user.role.startsWith('customer')" class="ml-4 text-caption"><a @click="$router.back()">戻る</a></div>
    <v-container fluid>
      <h2 text color="black" class="headline">トレーサビリティ</h2>
      <v-row>
        <v-col cols="12" class="align-self-center">
          <v-card class="v-card--flat">

            <v-toolbar
              id="search-field"
              color="primary"
              flat
              dark
              height="auto"
              min-width="550px"
              :class="'top-rounded ' + (
                  isReceptionRole
                    ? 'reception'
                    : isCenterRole
                      ? 'center'
                      : isCustomerRole
                        ? 'customer'
                        : ''
              )"
              :collapse="isCollapse"
            >

              <v-row style="width: 518px;" v-if="!isReceptionRole">
                <v-col cols="12" sm="10" class="align-self-center">
                  <v-radio-group
                    v-model="typeRadioGroupModel"
                    @change="changeType"
                    row
                    hide-details
                  >
                    <v-radio
                      key="holter"
                      label="ホルター心電計"
                      value="holter"
                    ></v-radio>
                    <v-radio
                      key="dongle"
                      label="通信用ドングル／ソフト"
                      value="dongle"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        @click="toggleCollapse()"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon v-if="!isCollapse">mdi-toggle-switch-outline</v-icon>
                        <v-icon v-else>mdi-toggle-switch-off-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>検索欄の表示/非表示</span>
                  </v-tooltip>
                </v-col>
              </v-row>

              <v-row class="align-center" v-if="!isCollapse" style="width: 100%;">

                <v-col cols="12" sm="2">
                  <v-select
                    dense
                    hide-details
                    eager
                    v-model="searchSelectedField"
                    label="検索フィールド"
                    :items="searchFieldItems"
                    @change="subscribeItems"
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" v-if="searchSelectedField == 'device_id' || searchSelectedField == 'lot_id'">
                  <v-text-field
                    dense
                    label="前方一致検索キーワード"
                    clearable
                    v-model="searchKeyword"
                    prepend-inner-icon="search"
                    @blur="subscribeItems"
                    @keyup.enter="subscribeItems"
                    hide-details
                    outlined
                  />
                </v-col>

                <v-col cols="12" sm="6" v-if="searchSelectedField == 'analysis_id'">
                  <v-text-field
                    dense
                    type="number"
                    label="完全一致検索キーワード"
                    clearable
                    v-model="searchKeyword"
                    prepend-inner-icon="search"
                    @blur="subscribeItems"
                    @keyup.enter="subscribeItems"
                    hide-details
                    outlined
                    min=1
                  />
                </v-col>

                <v-col cols="12" sm="3" v-if="searchSelectedField == 'date_types'">
                  <v-select
                    dense
                    hide-details
                    eager
                    v-model="searchSelectedDateType"
                    label="日付種別"
                    :items="searchDateTypeItems"
                    @change="subscribeItems"
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="3" v-if="searchSelectedField == 'date_types'">
                  <v-text-field
                    dense
                    type="date"
                    class="align-center"
                    v-model="searchStartDate"
                    label="開始日"
                    hide-details
                    clearable
                    :disabled="searchSelectedDateType == ''"
                  />
                </v-col>
                <span v-if="searchSelectedField == 'date_types'">&nbsp;〜&nbsp;</span>
                <v-col cols="12" sm="3" v-if="searchSelectedField == 'date_types'">
                  <v-text-field
                    dense
                    type="date"
                    class="align-center"
                    v-model="searchEndDate"
                    label="終了日"
                    hide-details
                    clearable
                    :disabled="searchSelectedDateType == ''"
                  />
                </v-col>
              </v-row>

              <v-row class="align-center" v-if="!isCollapse && searchSelectedField != 'analysis_id'" style="width: 100%;">

                <v-col cols="12" sm="4" v-if="!isCustomerRole">
                  <v-select
                    dense
                    hide-details
                    eager
                    v-model="searchSelectedCustomer"
                    label="医療機関"
                    :items="searchCustomerItems"
                    @change="subscribeItems"
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="3">
                  <v-select
                    dense
                    hide-details
                    eager
                    v-model="searchStatus"
                    label="状態"
                    :items="searchStatusItems"
                    @change="subscribeItems"
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="3">
                  <v-select
                    dense
                    hide-details
                    eager
                    v-model="searchEcgType"
                    label="心電計型式"
                    :items="searchEcgTypeItems"
                    @change="subscribeItems"
                  />
                </v-col>
              </v-row>

              <!-- <v-row class="align-center" v-if="!isCollapse && searchSelectedField != 'analysis_id'" style="width: 100%;">
                <v-col cols="12" v-if="this.typeRadioGroupModel == 'holter' && isManagementRole">
                  <v-divider class="ma-2"></v-divider>
                </v-col>
              </v-row>

              <v-row class="align-center" v-if="!isCollapse && searchSelectedField != 'analysis_id'" style="width: 100%;">

                <v-col cols="12" sm="3" v-if="this.typeRadioGroupModel == 'holter' && isManagementRole">
                  <v-select
                    dense
                    hide-details
                    eager
                    v-model="searchSelectedAnalysisOpinionType"
                    label="解析オプション"
                    :items="searchAnalysisOpinionTypeItems"
                    @change="subscribeItems"
                  ></v-select>
                </v-col>

              </v-row> -->


            </v-toolbar>
          </v-card>

        </v-col>
      </v-row>

      <v-row dense v-if="isOverQueryLimit">
        <v-col cols="12">
          <v-alert
            class="ma-0"
            dense
            dismissible
            outlined
            v-model="isOverQueryLimit"
            type="warning"
            prominent
          >
            <span v-for="(msg, index) in msgsOverQueryLimit" :key="index">
            {{ msg }}<br />
            </span>
          </v-alert>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12">

          <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page.sync=selectedLinesPerPage
            dense
            hide-default-footer
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            @update:sort-by="updateSortBy"
            @update:sort-desc="updateSortDesc"
            fixed-header
            height="700"
          >
            <template v-slot:top="{ pagination, options, updateOptions }">
              <v-data-footer
                :pagination="pagination"
                :options="options"
                @update:options="updateOptions"
                items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                :items-per-page-options="linesPerPageList"
                class="border-less"
              />
            </template>
            <template v-slot:item.action="{ item }">

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="viewItemForm(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon class="mx-3">remove_red_eye</v-icon>
                  </v-btn>
                </template>
                <span>詳細表示</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="isReceptionRole && canDisposeItem(item)"
                    icon
                    @click="disposeItem(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon class="mx-3">delete</v-icon>
                  </v-btn>
                </template>
                <span>廃棄記録</span>
              </v-tooltip>

            </template>
            <template v-slot:item.center_id="{ item }">
              {{centers[item.center_id] ? centers[item.center_id].name: ''}}
            </template>
            <template v-slot:item.reception_center_id="{ item }">
              {{receptions[item.reception_center_id] ? receptions[item.reception_center_id].name: ''}}
            </template>
            <template v-slot:item.order_org_id="{ item }">
              {{customerOrgs[item.order_org_id] ? customerOrgs[item.order_org_id].name: ''}}
            </template>
            <template v-slot:item.role="{ item }">
              {{roleNames[item.role]}}
            </template>
            <template v-slot:item.status="{ item }">
              <device-status-chip :item="item" />
            </template>
            <!-- 担当医 -->
            <template v-slot:item.doctor_names="{ item }">
              <div style="white-space: pre;">{{ item.doctor_names }}</div>
            </template>
            <!-- 解析オプション -->
            <template v-slot:item.analysis_opinion_type="{ item }">
              <div style="white-space: pre;">{{ item.analysis_opinion_type }}</div>
            </template>
            <!-- 心電計型式 -->
            <template v-slot:item.ecg_type="{ item }">
              {{ ecgType2Text(item) }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <inventory-dialog
      v-if="mode != 'none'"
      v-model="inventoryDialogModel"
      :mode="mode"
      :item="currentItem"
      :dialog-title="dialogTitle"
      @closed="onDialogClosed"
      @not-deleted="itemNotDeleted"
    />

  </div>
</template>

<script>
import dateformat from 'dateformat';
import InventoryDialog from '@/components/InventoryDialog.vue';
import DeviceStatusChip from '@/components/DeviceStatusChip.vue';
import AnalysisOpinionType from '@/mixins/AnalysisOpinionType.js';

let unsubscribeItems = null;
let itemsRef = null;

export default {
  name: 'Traceability',
  components: {
    InventoryDialog,
    DeviceStatusChip,
  },
  mixins: [
    AnalysisOpinionType,
  ],
  data() {
    return {
      itemsName: 'devices',
      typeRadioGroupModel: 'holter',
      inventoryDialogModel: false,
      headersHolter: [
        {
          text: '操作',
          align: 'left',
          sortable: false,
          value: 'action',
          width: '64px',
        },
        {
          text: 'デバイスID',
          align: 'left',
          sortable: true,
          value: 'device_id',
          width: '110px',
        },
        {
          text: '型式',
          align: 'left',
          sortable: true,
          value: 'ecg_type',
          width: '80px',
        },
        {
          text: '状態',
          align: 'left',
          sortable: true,
          value: 'status',
          width: '108px',
        },
        {
          text: 'デバイス受付センター',
          align: 'left',
          sortable: true,
          value: 'reception_center_id',
          width: '120px',
        },
        {
          text: '医療機関',
          align: 'left',
          sortable: true,
          value: 'order_org_id',
          width: '144px',
        },
        {
          text: '担当医',
          align: 'left',
          sortable: true,
          value: 'doctor_names',
          width: '144px',
        },
        // {
        //   text: '解析オプション',
        //   align: 'left',
        //   sortable: true,
        //   value: 'analysis_opinion_type',
        //   width: '90px',
        // },
        // {
        //   text: '解析ID',
        //   align: 'left',
        //   sortable: true,
        //   value: 'analysis_id',
        //   width: '90px',
        // },
        // {
        //   text: '製造日',
        //   align: 'left',
        //   sortable: true,
        //   value: 'produced',
        //   width: '80px',
        // },
        // {
        //   text: '使用期限日',
        //   align: 'left',
        //   sortable: true,
        //   value: 'expiration_date',
        //   width: '110px',
        // },
        // {
        //   text: '発送日',
        //   align: 'left',
        //   sortable: true,
        //   value: 'shipped',
        //   width: '80px',
        // },
        {
          text: '登録日時',
          align: 'left',
          sortable: true,
          value: 'received2',
          width: '100px',
        },
        {
          text: '患者装着日時',
          align: 'left',
          sortable: true,
          value: 'patient_attached',
          width: '100px',
        },
        {
          text: '受付日時',
          align: 'left',
          sortable: true,
          value: 'collected',
          width: '100px',
        },
        {
          text: '心電計受付日時',
          align: 'left',
          sortable: true,
          value: 'device_collected',
          width: '100px',
        },
        {
          text: '行動記録用紙受付日時',
          align: 'left',
          sortable: true,
          value: 'log_collected',
          width: '100px',
        },
        {
          text: '心電図アップロード日時',
          align: 'left',
          sortable: true,
          value: 'device_uploaded',
          width: '100px',
        },
        {
          text: '解析完了日時',
          align: 'left',
          sortable: true,
          value: 'analyzed',
          width: '100px',
        },
        {
          text: '廃棄日時',
          align: 'left',
          sortable: true,
          value: 'disposed',
          width: '100px',
        },
        {
          text: '更新日時',
          align: 'left',
          sortable: true,
          value: 'modified',
          width: '100px',
        },
      ],
      headersCenter: [
        {
          text: '操作',
          align: 'left',
          sortable: false,
          value: 'action',
          width: '110px',
        },
        {
          text: 'デバイスID',
          align: 'left',
          sortable: true,
          value: 'device_id',
          width: '110px',
        },
        {
          text: '型式',
          align: 'left',
          sortable: true,
          value: 'ecg_type',
          width: '80px',
        },
        {
          text: '状態',
          align: 'left',
          sortable: true,
          value: 'status',
          width: '108px',
        },
        {
          text: '医療機関',
          align: 'left',
          sortable: true,
          value: 'order_org_id',
          width: '108px',
        },
        {
          text: '担当医',
          align: 'left',
          sortable: true,
          value: 'doctor_names',
          width: '144px',
        },
        {
          text: '製造日',
          align: 'left',
          sortable: true,
          value: 'produced',
          width: '80px',
        },
        {
          text: '使用期限日',
          align: 'left',
          sortable: true,
          value: 'expiration_date',
          width: '110px',
        },
        {
          text: '発送日',
          align: 'left',
          sortable: true,
          value: 'shipped',
          width: '80px',
        },
        {
          text: '登録日時',
          align: 'left',
          sortable: true,
          value: 'received2',
          width: '100px',
        },
        {
          text: '患者装着日時',
          align: 'left',
          sortable: true,
          value: 'patient_attached',
          width: '100px',
        },
        {
          text: '受付日時',
          align: 'left',
          sortable: true,
          value: 'collected',
          width: '100px',
        },
        {
          text: '心電計受付日時',
          align: 'left',
          sortable: true,
          value: 'device_collected',
          width: '100px',
        },
        {
          text: '行動記録用受付日時',
          align: 'left',
          sortable: true,
          value: 'log_collected',
          width: '100px',
        },
        {
          text: '心電図アップロード日時',
          align: 'left',
          sortable: true,
          value: 'device_uploaded',
          width: '100px',
        },
        {
          text: '解析完了日時',
          align: 'left',
          sortable: true,
          value: 'analyzed',
          width: '100px',
        },
        {
          text: '廃棄日時',
          align: 'left',
          sortable: true,
          value: 'disposed',
          width: '100px',
        },
        {
          text: '更新日時',
          align: 'left',
          sortable: true,
          value: 'modified',
          width: '100px',
        },
      ],
      headersReception: [
        {
          text: '操作',
          align: 'left',
          sortable: false,
          value: 'action',
          width: '110px',
        },
        {
          text: 'デバイスID',
          align: 'left',
          sortable: true,
          value: 'device_id',
          width: '110px',
        },
        {
          text: '型式',
          align: 'left',
          sortable: true,
          value: 'ecg_type',
          width: '80px',
        },
        {
          text: '状態',
          align: 'left',
          sortable: true,
          value: 'status',
          width: '108px',
        },
        {
          text: '医療機関',
          align: 'left',
          sortable: true,
          value: 'order_org_id',
          width: '108px',
        },
        // {
        //   text: '製造日',
        //   align: 'left',
        //   sortable: true,
        //   value: 'produced',
        //   width: '80px',
        // },
        // {
        //   text: '使用期限日',
        //   align: 'left',
        //   sortable: true,
        //   value: 'expiration_date',
        //   width: '110px',
        // },
        // {
        //   text: '発送日',
        //   align: 'left',
        //   sortable: true,
        //   value: 'shipped',
        //   width: '80px',
        // },
        {
          text: '登録日時',
          align: 'left',
          sortable: true,
          value: 'received2',
          width: '100px',
        },
        {
          text: '患者装着日時',
          align: 'left',
          sortable: true,
          value: 'patient_attached',
          width: '100px',
        },
        {
          text: '受付日時',
          align: 'left',
          sortable: true,
          value: 'collected',
          width: '100px',
        },
        {
          text: '心電計受付日時',
          align: 'left',
          sortable: true,
          value: 'device_collected',
          width: '100px',
        },
        {
          text: '行動記録用受付日時',
          align: 'left',
          sortable: true,
          value: 'log_collected',
          width: '100px',
        },
        {
          text: '心電図アップロード日時',
          align: 'left',
          sortable: true,
          value: 'device_uploaded',
          width: '100px',
        },
        {
          text: '解析完了日時',
          align: 'left',
          sortable: true,
          value: 'analyzed',
          width: '100px',
        },
        {
          text: '廃棄日時',
          align: 'left',
          sortable: true,
          value: 'disposed',
          width: '100px',
        },
        {
          text: '更新日時',
          align: 'left',
          sortable: true,
          value: 'modified',
          width: '100px',
        },
      ],
      headersCustomer: [
        {
          text: '操作',
          align: 'left',
          sortable: false,
          value: 'action',
          width: '64px',
        },
        {
          text: 'デバイスID',
          align: 'left',
          sortable: true,
          value: 'device_id',
          width: '110px',
        },
        {
          text: '型式',
          align: 'left',
          sortable: true,
          value: 'ecg_type',
          width: '80px',
        },
        {
          text: '状態',
          align: 'left',
          sortable: true,
          value: 'status',
          width: '108px',
        },
        // {
        //   text: '製造日',
        //   align: 'left',
        //   sortable: true,
        //   value: 'produced',
        //   width: '80px',
        // },
        // {
        //   text: '使用期限日',
        //   align: 'left',
        //   sortable: true,
        //   value: 'expiration_date',
        //   width: '110px',
        // },
        // {
        //   text: '発送日',
        //   align: 'left',
        //   sortable: true,
        //   value: 'shipped',
        //   width: '80px',
        // },
        {
          text: '登録日時',
          align: 'left',
          sortable: true,
          value: 'received2',
          width: '100px',
        },
        {
          text: '患者装着日時',
          align: 'left',
          sortable: true,
          value: 'patient_attached',
          width: '100px',
        },
        {
          text: '受付日時',
          align: 'left',
          sortable: true,
          value: 'collected',
          width: '100px',
        },
        {
          text: '心電計受付日時',
          align: 'left',
          sortable: true,
          value: 'device_collected',
          width: '100px',
        },
        {
          text: '行動記録用紙受付日時',
          align: 'left',
          sortable: true,
          value: 'log_collected',
          width: '100px',
        },
        {
          text: '心電図アップロード日時',
          align: 'left',
          sortable: true,
          value: 'device_uploaded',
          width: '100px',
        },
        {
          text: '解析完了日時',
          align: 'left',
          sortable: true,
          value: 'analyzed',
          width: '100px',
        },
        {
          text: '廃棄日時',
          align: 'left',
          sortable: true,
          value: 'disposed',
          width: '100px',
        },
        {
          text: '更新日時',
          align: 'left',
          sortable: true,
          value: 'modified',
          width: '100px',
        },
      ],
      dongleHeaders: [
        {
          text: '操作',
          align: 'left',
          sortable: false,
          value: 'action',
          width: '64px',
        },
        {
          text: 'ロットID',
          align: 'left',
          sortable: true,
          value: 'lot_id',
          width: '135px',
        },
        {
          text: '状態',
          align: 'left',
          sortable: true,
          value: 'status',
          width: '108px',
        },
        {
          text: 'デバイス受付センター',
          align: 'left',
          sortable: true,
          value: 'reception_center_id',
          width: '125px',
        },
        {
          text: '医療機関',
          align: 'left',
          sortable: true,
          value: 'order_org_id',
          width: '108px',
        },
        // {
        //   text: '発送日',
        //   align: 'left',
        //   sortable: true,
        //   value: 'shipped',
        //   width: '80px',
        // },
        {
          text: '登録日時',
          align: 'left',
          sortable: true,
          value: 'received2',
          width: '100px',
        },
        {
          text: '更新日時',
          align: 'left',
          sortable: true,
          value: 'modified',
          width: '100px',
        },
      ],
      dongleHeadersCenter: [
        {
          text: '操作',
          align: 'left',
          sortable: false,
          value: 'action',
          width: '64px',
        },
        {
          text: 'ロットID',
          align: 'left',
          sortable: true,
          value: 'lot_id',
          width: '135px',
        },
        {
          text: '状態',
          align: 'left',
          sortable: true,
          value: 'status',
          width: '108px',
        },
        {
          text: '医療機関',
          align: 'left',
          sortable: true,
          value: 'order_org_id',
          width: '108px',
        },
        {
          text: '発送日',
          align: 'left',
          sortable: true,
          value: 'shipped',
          width: '80px',
        },
        {
          text: '登録日時',
          align: 'left',
          sortable: true,
          value: 'received2',
          width: '100px',
        },
        {
          text: '更新日時',
          align: 'left',
          sortable: true,
          value: 'modified',
          width: '100px',
        },
      ],
      dongleHeadersCustomer: [
        {
          text: '操作',
          align: 'left',
          sortable: false,
          value: 'action',
          width: '64px',
        },
        {
          text: 'ロットID',
          align: 'left',
          sortable: true,
          value: 'lot_id',
          width: '100px',
        },
        {
          text: '状態',
          align: 'left',
          sortable: true,
          value: 'status',
          width: '108px',
        },
        // {
        //   text: '発送日',
        //   align: 'left',
        //   sortable: true,
        //   value: 'shipped',
        //   width: '80px',
        // },
        {
          text: '登録日時',
          align: 'left',
          sortable: true,
          value: 'received2',
          width: '140px',
        },
        {
          text: '更新日時',
          align: 'left',
          sortable: true,
          value: 'modified',
          width: '100px',
        },
      ],
      headers:[],
      emailRules: [
        v => !!v || '入力は必須です。',
        v => /.+@.+\..+/.test(v) || 'Emailの形式が正しくありません。',
      ],
      nameRules: [
        v => !!v || '入力は必須です。',
      ],
      postalCodeRules: [
        v => !!v || '入力は必須です。',
      ],
      prefRules: [
        v => !!v || '入力は必須です。',
      ],
      cityRules: [
        v => !!v || '入力は必須です。',
      ],
      uniqueIdRules: [],
      remarksRules: [],
      items: [],
      currentItem: {},
      mode: 'none',
      recordDialogTitle: '登録',
      configRobots: {},
      modelNumbers: [],
      searchKeyword: this.$route.params ?
        this.$route.params.cmd == 'search_device_id' ?
          this.$route.params.device_id
          : ''
        : '',
      accessTypeModel: 'all',
      // 上書きされる可能性のあるプロパティ
      searchSelectedField: '',
      searchFieldItems: [],
      searchSelectedDateType: '',
      searchDateTypeItems: [],
      searchSelectedCustomer: '',
      searchSelectedAnalysisOpinionType: -1,
      searchStatus: -1,
      searchEcgType: -1,
      searchAnalysisId: '',
      centers: {},
      receptiomns: {},
      customerOrgs: {},
      dialogTitle: '',
      selectedLinesPerPage: 10,
      linesPerPageList: [10, 30, 50],
      canDisposeItemStatusList: [5, 6, 7, 11],  // 端末の状態が、受付センター着荷、解析中、解析完了、解析エラーのものが対象
      cacheDoctorNames: {},
      isCollapse: true,
      searchStartDate: '',
      searchEndDate: '',
      isOverQueryLimit: false,
      msgsOverQueryLimit: [],
      sortBy: ['modified'],
      sortDesc: [true],
    };
  },
  computed: {
    logDateFormatted() {
      return this.logDate.toLocaleDateString().replace(/\//g, '-');
    },
    isReceptionRole() {
      if (this.$store.state.user.role.startsWith('reception')) return true;
      return false;
    },
    isCustomerRole() {
      if (this.$store.state.user.role.startsWith('customer')) return true;
      return false;
    },
    isCenterRole() {
      if (this.$store.state.user.role.startsWith('center')) return true;
      return false;
    },
    isManagementRole() {
      if (this.$store.state.user.role.startsWith('management')) return true;
      return false;
    },
    searchStatusItems() {
      if (this.isReceptionRole) {
        return this.$store.state.deviceStatusNameValueDictForReception;
      } else if (this.isCustomerRole) {
        return this.$store.state.deviceStatusNameValueDictForCustomer;
      } else {
        return this.$store.state.deviceStatusNameValueDict;
      }
    },
    searchEcgTypeItems() {
      const items = [{ text: '全て', value: -1 }]
      for (const key in this.$store.state.ecgTypes) {
        if (key == 1) continue; // PX 1.0 は除く
        items.push({
          text: this.$store.state.ecgTypes[parseInt(key)],
          value: parseInt(key)
        })
      }
      return items;
    },
    searchCustomerItems() {
      return this.getOrgItems(this.customerOrgs);
    },
    searchAnalysisOpinionTypeItems() {
      let fields = [{text: '全て', value: -1}];
      for (let i = 0; i < this.ANALYSIS_OPINION_TYPES.length; i++) {
        const name = this.ANALYSIS_OPINION_TYPES[i];
        fields.push({
          text: name,
          value: i
        });
      }
      return fields;
    }
  },
  methods: {
    closeDialog() {
    },
    newItemForm() {
      this.mode = 'new';
      this.inventoryDialogModel = true;
    },
    viewItemForm(item) {
      // console.log('viewItemForm', item);
      this.dialogTitle = 'デバイス情報',
      this.mode = 'traceability';
      this.currentItem = item;
      this.inventoryDialogModel = true;
    },
    async disposeItem(item) {
      console.log('disposeItem', item);
      const result = await this.$root.$confirm(`確認`, `このデバイス ${item.device_id} の廃棄を記録しますか？`);
      if (!result) return;
      await this.disposeItemBody(item);
    },
    editItemForm(item) {
      this.mode = 'edit';
      this.currentItem = item;
      this.inventoryDialogModel = true;
    },
    deleteItem(item) {
      this.mode = 'delete';
      this.currentItem = item;
    },
    onDialogClosed() {
      // 削除完了後に呼び出されるCallback
      this.mode = 'none';
      this.currentItem = null;
    },
    itemNotDeleted() {
      // 削除完了後に呼び出されるCallback
      this.mode = 'none';
      // console.log('item-not-deleted');
    },
    changeType(type) {
      if (type == 'holter') {
        if (this.isCustomerRole) {
          this.headers = this.headersCustomer;
        } else if (this.isCenterRole) {
          this.headers = this.headersCenter;
        } else if (this.isReceptionRole) {
          this.headers = this.headersReception;
        } else {
          this.headers = this.headersHolter;
        }
      } else {
        if (this.isCustomerRole) {
          this.headers = this.dongleHeadersCustomer;
        } else if (this.isCenterRole){
          this.headers = this.dongleHeadersCenter;
        } else {
          this.headers = this.dongleHeaders;
        }
      }

      this.searchSelectedField = this.typeRadioGroupModel == 'holter' ? 'device_id' : 'lot_id';

      if (this.isManagementRole) {
        this.searchFieldItems = this.typeRadioGroupModel == 'holter'
          ? [
            {text: 'デバイスID', value: 'device_id'},
            {text: '各種日付範囲', value: 'date_types'}
            ]
          : [
            {text: 'ロットID', value: 'lot_id'},
            {text: '各種日付範囲', value: 'date_types'}
          ];
      } else {
        this.searchFieldItems = this.typeRadioGroupModel == 'holter'
          ? [
            {text: 'デバイスID', value: 'device_id'},
          ]
          : [
            {text: 'ロットID', value: 'lot_id'},
          ];
      }

      this.searchDateTypeItems = this.typeRadioGroupModel == 'holter'
        ? [
            {text: '指定なし', value: ''},
            {text: '解析完了日', value: 'analyzed'},
        ]
        : [
          {text: '指定なし', value: ''},
          {text: '登録日', value: 'received2'},
        ];

      this.searchStatus = -1;
      this.searchEcgType = -1;
      this.searchEndDate = '';
      this.searchStartDate = '';
      this.searchKeyword = '';
      this.searchSelectedDateType = '';
      this.subscribeItems();
    },
    subscribeItems() {
      itemsRef = this.$db.collection(`${this.itemsName}`);
      // 購読中は一旦解除した上で対応する。
      if (unsubscribeItems) unsubscribeItems();
      this.searchKeyword = this.searchKeyword ? this.searchKeyword.trim(): '';

      let query = itemsRef
        .where('deleted', '==', null)
        .where('type', '==', this.typeRadioGroupModel);

      // 所属でフィルタ
      if (this.$store.state.user.role.startsWith('customer')){
        // 医療機関の場合、所属する医療機関のデータのみ
        query = query.where('order_org_id', '==', this.$store.state.user.org_id);
      } else if (this.$store.state.user.role.startsWith('center')) {
        // 販売組織スタッフの場合、所属するセンターのデータのみ
        query = query.where('center_id', '==', this.$store.state.user.org_id);
      } else if (this.$store.state.user.role.startsWith('reception')) {
        // デバイス受付センタースタッフの場合、所属するセンターのデータのみ
        query = query.where('reception_center_id', '==', this.$store.state.user.org_id);
      }

      if (this.searchSelectedField == 'analysis_id') {
        // 解析IDが指定されていた場合
        if (this.searchKeyword.length > 0) query = query.where('analysis_id', '==', parseInt(this.searchKeyword));
      } else {
        // その他が指定されていた場合

        // 状態でフィルタ
        if (this.searchStatus != -1)  query = query.where('status', '==', this.searchStatus);

        // 心電計型式でフィルタ
        if (this.searchEcgType != -1)  query = query.where('ecg_type', '==', this.searchEcgType);

        // 医療機関でフィルタ
        if (this.searchSelectedCustomer != '') query = query.where('order_org_id', '==', this.searchSelectedCustomer);

        if (this.searchSelectedField == 'date_types' && this.searchSelectedDateType != '') {
          // 各種日付で範囲選択
          if (
            this.searchStartDate
            && this.searchStartDate.trim() != ''
            && (new Date(this.searchStartDate + ' 00:00:00')).toString() !== "Invalid Date"
          ) {
            const startDateTime = new Date(this.searchStartDate + ' 00:00:00');
            query = query.where(this.searchSelectedDateType, ">=", startDateTime);
          }
          if (
            this.searchEndDate
            && this.searchEndDate.trim() != ''
            && (new Date(this.searchEndDate + ' 23:59:59')).toString() !== "Invalid Date"
          ) {
            const endDateTime = new Date(this.searchEndDate + ' 23:59:59');
            query = query.where(this.searchSelectedDateType, "<=", endDateTime);
          }
        } else if ((this.searchSelectedField == 'device_id' || this.searchSelectedField == 'lot_id') && this.searchKeyword.length > 0) {
          // 検索キーワードで前方検索
          let startAt = this.searchKeyword;
          let endAt = this.searchKeyword + '\uffff';
          query = query.orderBy(this.searchSelectedField).startAt(startAt).endAt(endAt);
        } else {
          query = query.orderBy('modified');
        }

      }

      // 上限（上限に達したことを判定するために＋１件する）
      const queryLimit = this.$store.state.configs.query_limit;
      this.msgsOverQueryLimit = [
        `表示対象データが${queryLimit}件を超えました。${queryLimit + 1}件以上は表示されません。${queryLimit}件以内にするためには各種検索条件を調整してください。`,
        '※ 「解析オプション」では調整できません。'
      ];
      query = query.limit(queryLimit + 1);

      const ts2str = (ts) => {
        return ts ? dateformat(new Date(ts.seconds * 1000), 'yyyy/mm/dd HH:MM:ss'): '';
      }

      unsubscribeItems = query.onSnapshot(async (querySnapshot) => {
        // 最初および変更時の読み取り
        let listCount = 0;
        this.isOverQueryLimit = false;
        this.items = [];

        // クエリ上限を超えた場合
        if (querySnapshot.docs.length > queryLimit) this.isOverQueryLimit = true;

        querySnapshot.forEach(async (doc) => {

          // クエリ件数が上限を超えた場合は終了
          listCount = listCount + 1;
          if (listCount > queryLimit) return;

          let data = doc.data();
          data.created = ts2str(data.created);
          data.modified = ts2str(data.modified);
          data.produced = data.produced ? dateformat(new Date(data.produced.seconds * 1000), 'yyyy/mm/dd'): '';
          data.expiration_date = data.expiration_date ? dateformat(new Date(data.expiration_date.seconds * 1000), 'yyyy/mm/dd'): '';
          data.collected = ts2str(data.collected);
          data.arrived = ts2str(data.arrived);
          data.shipped = data.shipped ? dateformat(new Date(data.shipped.seconds * 1000), 'yyyy/mm/dd'): '';
          data.received1 = ts2str(data.received1);
          data.received2 = ts2str(data.received2);
          data.patient_attached = ts2str(data.patient_attached);
          data.device_collected = ts2str(data.device_collected);
          data.log_collected = ts2str(data.log_collected);
          data.device_uploaded =  ts2str(data.device_uploaded);
          data.log_uploaded =  ts2str(data.log_uploaded);
          data.analyzed = ts2str(data.analyzed);
          data.disposed = ts2str(data.disposed);
          data.id = doc.id;

          // 患者情報取得
          data['doctor_names'] = '';
          data['analysis_opinion_type'] = '';
          if ((this.isManagementRole || this.isCenterRole) && 'patient_id' in data && data.patient_id) {
            // 運営主体／販売組織、かつ患者情報がある場合
            this.$db.collection('patients').doc(data.patient_id).get().then(async (patientDoc) => {
              if (!patientDoc.exists) return;
              const patientData = patientDoc.data();

              // 担当医名の取得
              if ('customer_staff_id_list' in patientData && patientData.customer_staff_id_list.length > 0) {
                let doctorNames = [];
                for (const id of patientData.customer_staff_id_list) {
                  let doctorName = '';
                  if (this.cacheDoctorNames[id]) {
                    // キャッシュに有る場合、キャッシュから取得
                    doctorName = this.cacheDoctorNames[id];
                  } else {
                    // キャッシュに無い場合、Firestoreから取得
                    const doctorDoc = await this.$db.collection('customer_staffs').doc(id).get();
                    if (!doctorDoc.exists) continue;
                    const doctorData = doctorDoc.data();
                    if (doctorData.deleted) continue;
                    doctorName = doctorData.name;
                    // キャッシュに格納（少しでも速度向上のため）
                    this.cacheDoctorNames[id] = doctorData.name;
                  }
                  doctorNames.push(doctorName);
                }
                data['doctor_names'] = this.displayFormatDoctors(doctorNames);
              }

              // 解析オプションの取得
              data['analysis_opinion_type'] = this.getDisplayAnalysisOpinionTypeString(patientData, data.status, this.customerOrgs[data.order_org_id]);

              // 解析オプションでフィルタ
              if (this.searchSelectedAnalysisOpinionType != -1) {
                if (data['analysis_opinion_type'] !== this.ANALYSIS_OPINION_TYPES[this.searchSelectedAnalysisOpinionType]) return;
                // 解析オプションが指定されている場合は、ここでリストを追加する
                this.items.push(data);
              }
            }).catch((error) => {
              // 運営主体以外は、存在しない患者／スタッフ情報にアクセスするとFirestoreのルールによりエラーとなる
              console.warn(data.patient_id, error);
            });
          }

          // ドングルの場合、又は解析オプションが指定されていない場合は、ここでデータをリストに追加する
          if (this.typeRadioGroupModel == 'dongle' || this.searchSelectedAnalysisOpinionType == -1) this.items.push(data);
        });
      }, (e) => {
        console.log('error', e);
        this.items = [];
      });
    },
    displayFormatDoctors(doctors) {
      let text = '';
      for (const doctor of doctors) {
        text = text + doctor + '\n';
      }
      return text.trim();
    },
    updateLogDate() {
      // console.log('updateLogDate');
    },
    isSwitchingUser() {
      // superUserが存在しない場合は、なりすまし不可
      if (!this.$store.state.superUser) {
        return false;
      }
      // ログイン中のユーザーIDと、superUserのIDが同一の場合はなりすましではない
      if (this.$store.state.user.id == this.$store.state.superUser.id) {
        return false;
      }
      return true;
    },
    // 廃棄記録本体
    async disposeItemBody(item) {
      try {
        const deviceId = item.device_id;

        // デバイスデータ取得
        const docRef = this.$db.doc('devices/' + deviceId);
        const doc = await docRef.get();
        if (!doc.exists) {
          await this.$root.$alert('エラー', `そのようなデバイス ${deviceId} は存在しません。`);
          return;
        }
        const deviceData = doc.data();
        if (!this.canDisposeItemStatusList.includes(deviceData.status)) {
          await this.$root.$alert('エラー', `このデバイス ${deviceId} は受付されていません。`);
          return;
        }

        // デバイスデータ更新
        let updateData = {
          status: 9,
          disposed: this.$firebase.firestore.FieldValue.serverTimestamp(),
          disposed_staff_id: this.$store.state.user.id,
          modified:  this.$firebase.firestore.FieldValue.serverTimestamp(),
        }
        await docRef.update(updateData);
        await this.writeLog(['device_dispose'], `廃棄記録しました。`, item); // 操作記録

        // 該当デバイス受付センターの在庫関連の調整
        const refReceptionCenter = this.$db.collection('staff_orgs').doc(deviceData.reception_center_id);
        await refReceptionCenter.update({ holter_stock_quantity_disposed: this.$firebase.firestore.FieldValue.increment(1) });
        if (deviceData.status == 5) {
          // 受付センター着荷状態の場合は受付数を減らす
          await refReceptionCenter.update({ holter_stock_quantity: this.$firebase.firestore.FieldValue.increment(-1) });
        } else {
          // 保管状態の場合は保管数を減らす
          await refReceptionCenter.update({ holter_stock_quantity_keeped: this.$firebase.firestore.FieldValue.increment(-1) });
        }
      } catch (e) {
        console.error(e);
        this.$root.$alert('エラー', '廃棄記録に失敗しました。' + e);
      }
    },
    async writeLog(tags, message, item, option = null) {
      const deviceType = item.type == 'dongle' ? '通信用ドングル／ソフト' : 'ホルター心電計';
      message = `デバイス：${deviceType} (${item.device_id})：${message}`
      await this.$functions.log({ tags, message, item: option });
    },
    canDisposeItem(item) {
      if (item.disposed) {
        return false;
      }
      if (!this.canDisposeItemStatusList.includes(item.status)) {
        return false;
      }
      return true;
    },
    toggleCollapse() {
      this.isCollapse = !this.isCollapse;
    },
    getOrgItems(orgs) {
      let fields = [{text: '全て', value: ''}];
      for (const id in orgs) {
        const org = orgs[id];
        fields.push({
          text: org.name,
          value: org.id
        });
      }
      return fields;
    },
    updateSortBy(v) {
      this.sortBy = v;
    },
    updateSortDesc(v) {
      this.sortDesc = v;
    },
    ecgType2Text(v) {
      if (/^T-[0-9]{6}$/.test(v.device_id)) {
        return this.$store.state.ecgTypes[1];
      }

      if (/^T-[A-Z][0-9]{6}$/.test(v.device_id)) {
        return this.$store.state.ecgTypes[2];
      }
      
      return '';
    },
  },
  watch: {
    selectedLinesPerPage(lineNumber) {
      if (this.$store.state.user.table_lines_par_page != lineNumber) {
        const collectionName = this.$store.state.user.role.startsWith('customer') ? 'customer_staffs' : 'staffs';
        const staffsRef = this.$db
          .collection(collectionName)
          .doc(this.$store.state.user.id);
        // 運営主体管理ユーザーによる、なりすましの場合は更新しない
        if (!this.isSwitchingUser()) {
          staffsRef.update({
            table_lines_par_page: lineNumber
          });
        }
        this.$store.commit('setUserTableLinesParPage', lineNumber);
      }
    },
    searchStartDate(date) {
      if (date) {
        date = new Date(date + ' 00:00:00');
        if (date.toString() === "Invalid Date") return;
      }
      this.subscribeItems();
    },
    searchEndDate(date) {
      if (date) {
        date = new Date(date + ' 23:59:59');
        if (date.toString() === "Invalid Date") return;
      }
      this.subscribeItems();
    },
  },
  mounted() {
    // テーブル表示件数の初期読み込み
    if (this.$store.state.user.table_lines_par_page) {
      this.selectedLinesPerPage = this.$store.state.user.table_lines_par_page;
    }
    // 他の画面で表示件数が変更された場合、こちらの画面にも反映させる
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'setUserTableLinesParPage') {
        this.selectedLinesPerPage = state.user.table_lines_par_page;
      }
      if (mutation.type === 'setCenters') {
        this.centers = this.$store.state.centersDict;
      }
      if (mutation.type === 'setReceptions') {
        this.receptions = this.$store.state.receptionsDict;
      }
      if (mutation.type === 'setCustomerOrgs') {
        this.customerOrgs = this.$store.state.customerOrgsDict;
      }
    });

    this.centers = this.$store.state.centersDict;
    this.receptions = this.$store.state.receptionsDict;
    this.customerOrgs = this.$store.state.customerOrgsDict;

    // デバイス受付センターは検索欄常時表示
    this.isCollapse = this.isReceptionRole ? false : true;

    // デバイスタイプの指定
    if (
      'device_type' in this.$route.params
      && (
        this.$route.params.device_type === 'holter'
        || this.$route.params.device_type === 'dongle'
      )
    ) {
      // 指定が有る場合はそれに従う
      this.typeRadioGroupModel = this.$route.params.device_type;
    } else if (this.$store.state.user.role.startsWith('customer')) {
      // 指定が無く、かつ医療機関の場合はドングルを初期表示する
      this.typeRadioGroupModel = 'dongle';
    }
    this.changeType(this.typeRadioGroupModel);

  },
  beforeDestroy() {
    // 変更監視を停止
    if (unsubscribeItems) unsubscribeItems();
  }
}
</script>

<style scoped>
  .theme-color {
    color: white;
    background-color:  #B3131A;
  }

  .top-rounded {
    margin:0px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .border-less {
    border: none !important;
  }

  header#search-field {
    height: 200px !important;
    transition-property: height, max-width, width;
    transition-duration: 0.25s, 0.25s, 0.25s;
  }

  header#search-field.reception {
    height: auto !important;
    padding:12px 12px 12px 12px;
  }

  header#search-field.v-toolbar--collapsed {
    height: 55px !important;
  }
</style>

<style>
  input[type="date"]::-webkit-calendar-picker-indicator {
    background-color: white;
    border-radius: 5px;
    margin: 0;
  }

  header#search-field .v-toolbar__content {
    flex-flow: column;
    align-items: baseline;
  }
</style>